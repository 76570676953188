/* eslint-disable camelcase */
// context
import documentContext from '../../contexts/translate/es.json'

// components
import AddRecordUniq from '../../components/AddRecordingUniq/translate/es.json'
import header_es from '../../components/Header/translate/es.json'
import printButton from '../../components/PrintButton/translate/es.json'
import sidebar_es from '../../components/Sidebar/translate/es.json'
import webcamStreamComponent from '../../components/Webcam/translate/es.json'
// pages (/pages)
import pageClients_es from '../../pages/Clients/translate/es.json'
import pageProducts from '../../pages/ProductGroups/translate/es.json'
import pageServices from '../../pages/Services/translate/es.json'

// pages/**
import pageAttendanceScreen from '../../pages/Attendance/translate/es.json'
import pageBillsToPay from '../../pages/BillsToPay/translate/es.json'
import pageBillsToReceive from '../../pages/BillsToReceive/translate/es.json'
import pageCardsMedia from '../../pages/CardsMediaClients/translate/es.json'
import pageCheckout from '../../pages/Checkout/translate/es.json'
import pageComparisons from '../../pages/Comparisons/translate/es.json'
import pageCreatingForms from '../../pages/CreatingForms/translate/es.json'
import pageDocumentPrint from '../../pages/DocumentPrint/translate/es.json'
import pageDocumentsServices from '../../pages/DocumentsServices/translate/es.json'
import pageExamRequest from '../../pages/ExamRequest/translate/es.json'
import pageMeansToReceipts from '../../pages/MeansToReceipts/translate/es.json'
import pagePaymentBill from '../../pages/PaymentBill/translate/es.json'
import pageProductsItems from '../../pages/ProductItems/translate/es.json'
import pageProductsItemsResale from '../../pages/ProductItemsResale/translate/es.json'
import pageProductsHistory from '../../pages/ProductsHistory/translate/es.json'
import pageRealizedCashFlow from '../../pages/RealizedCashFlow/translate/es.json'
import pageRecipe from '../../pages/Recipe/translate/es.json'
import pageReportsTransfer from '../../pages/reportsTransfer/translate/es.json'
import pageScheduling from '../../pages/Scheduling/translate/es.json'
import pageClinic from '../../pages/Settings/AboutClinic/translate/es.json'
import pageAttendance from '../../pages/Settings/Attendance/translate/es.json'
import pageCostCenters from '../../pages/Settings/CostCenters/translate/es.json'
import pageConvenio from '../../pages/Settings/Covenants/translate/es.json'
import pageOrigins from '../../pages/Settings/Origins/translate/es.json'
import pagePaymentSources from '../../pages/Settings/PaymentSources/translate/es.json'
import pageRequiredFields from '../../pages/Settings/RequiredFields/translate/es.json'
import pageSuppliers from '../../pages/Settings/Suppliers/translate/es.json'
import pageTags from '../../pages/Settings/Tags/translate/es.json'
import pageUsers from '../../pages/Settings/Users/translate/es.json'
import pageTagsServices from '../../pages/TagsServices/translate/es.json'
import pageTaxControl from '../../pages/TaxControl/translate/es.json'
import pageTaxUnitControl from '../../pages/TaxControlUnit/translate/es.json'
import pageBudgets from '../../pages/Budgets/translate/es.json'
import pageIndications from '../../pages/Indications/translate/es.json'
import pageDailyBox from '../../pages/DailyBox/translate/es.json'
import pageReportsFinancial from '../../pages/ReportsFinancial/translate/es.json'
import pageReportsServicesAndProducts from '../../pages/ReportsServicesAndProducts/translate/es.json'
import pageReportsMarketing from '../../pages/ReportsMarketing/translate/es.json'
import pageTicketsCanceled from '../../pages/TicketsCanceled/translate/es.json'
import pageBlockTimes from '../../pages/BlockTimes/translate/es.json'
import pageEquipments from '../../pages/Settings/Equipments/translate/es.json'
import pageRegisterClinic from '../../pages/RegisterClinic/translate/es.json'
import pageReportsOperation from '../../pages/ReportsOperation/translate/es.json'

export const es = {
  header: header_es,
  sidebar: sidebar_es,
  printButton,
  AddRecordUniq,
  pageClients: pageClients_es,
  pageClinic,
  pageUsers,
  pageConvenio,
  pageAttendance,
  pageTags,
  pageOrigins,
  pageRequiredFields,
  pageSuppliers,
  pageProducts,
  pageServices,
  pageCostCenters,
  pagePaymentSources,
  pageBillsToPay,
  pagePaymentBill,
  pageScheduling,
  pageTagsServices,
  pageDocumentsServices,
  pageAttendanceScreen,
  pageRecipe,
  pageExamRequest,
  pageCardsMedia,
  documentContext,
  webcamStreamComponent,
  pageComparisons,
  pageMeansToReceipts,
  pageCreatingForms,
  pageBillsToReceive,
  pageCheckout,
  pageProductsItems,
  pageProductsHistory,
  pageRealizedCashFlow,
  pageTaxControl,
  pageTaxUnitControl,
  pageDocumentPrint,
  pageReportsTransfer,
  pageProductsItemsResale,
  pageBudgets,
  pageIndications,
  pageDailyBox,
  pageReportsFinancial,
  pageReportsServicesAndProducts,
  pageTicketsCanceled,
  pageReportsMarketing,
  pageBlockTimes,
  pageEquipments,
  pageRegisterClinic,
  pageReportsOperation,
  global: {
    btnUpdate: 'Atualizar',
    btnNovo: 'Novo',
    dropzoneLabel: 'Clique ou arraste arquivos aqui',
    dropzoneLabelError: 'Arquivo não suportado!',
    dropzoneLabelSuccess: 'Arquivo suportado.',
    dropzoneLabelSize: 'Atenção! arquivos até',
    remove: 'Excluir',
    documentComponent: {
      i1: 'Tipo de documento',
      i1Option: {
        i1: 'CPF',
        i2: 'CNPJ',
        i3: 'Outros'
      },
      i2: 'Documento'
    }
  }
}
