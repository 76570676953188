/* eslint-disable camelcase */
import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../../components/molecules/CardContainer'

import { Select } from '../../../components/Form/Select'
import { Switch } from '../../../components/Form/Switch'
import { Header } from '../../../components/Header'
import { Sidebar } from '../../../components/Sidebar'
import { useAuth } from '../../../hooks/auth'
import { apiAuth } from '../../../services/apiAuth'
import { SelectComponent } from '../../../components/Form/SelectComponent'
import { Input } from '../../../components/Form/Input'
import {
  MasksTypes,
  TextFieldControlled
} from '../../../components/Form/TextFieldControlled'
import { useModal } from '../../../hooks/useModal'
import { diffArrays, diffArraysSub } from '../../../utils/fns/diffArrays'

type FormData = {
  birthDate: boolean
  document: boolean
  recordsInsertAfter: boolean
  origin: boolean
}

type ClinicData = {
  patient_birth_date: boolean
  patient_document: boolean
  patient_origin: boolean
  patient_email: boolean
  patient_view_email: boolean
  hours_attendance_start: null | number
  hours_attendance_cancel: null | number
  hours_attendance_finish: null | number
  adding_itens_to_attendance: boolean
  documents: Array<Documents>
  documents_preferences: Array<DocumentsPreferences>
}

type DocumentsPreferences = {
  id: number
  label: string
  is_view: boolean
  document: Documents
  is_required: boolean
}

type Documents = {
  id: number
  name_ptbr: string
  name_en: string
  acronym: string
  index: number
  regex: MasksTypes
  is_required: boolean
  is_view: boolean
}

type DocumentsOptions = Array<{
  label: string
  value: number
}>

type DocumentsSwitch = Array<{
  label: string
  value: number
  isRequired: boolean
  isView: boolean
}>

export function RequiredFields() {
  const { clinicId, decodeToken } = useAuth()
  const { clinic_language } = decodeToken
  const [t] = useTranslation('pageRequiredFields')
  const toast = useToast()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const optionsDefaultHours = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' }
  ]

  // states
  const [clinic, setClinic] = useState<ClinicData | null>(null)
  const [attendanceStart, setAttendanceStart] = useState('6')
  const [attendanceFinish, setAttendanceFinish] = useState('6')
  const [attendanceFinishBySystem, setAttendanceFinishBySystem] = useState('6')
  const [isBirthDate, setIsBirthDate] = useState(false)
  const [isDocument, setIsDocument] = useState(false)
  const [isOrigin, setIsOrigin] = useState(false)
  const [documents, setDocuments] = useState<DocumentsOptions>([])
  const [documentsEntities, setDocumentsEntities] = useState<DocumentsOptions>(
    []
  )
  const [documentsSwitch, setDocumentsSwitch] = useState<DocumentsSwitch>([])
  const [documentsClinic, setDocumentsClinic] = useState<Documents[]>([])
  const [documentsSwitchEntities, setDocumentsSwitchEntities] =
    useState<DocumentsSwitch>([])
  const [optionsDocuments, setOptionsDocuments] = useState<DocumentsOptions>([])
  const [optionsDocumentsEntities, setOptionsDocumentsEntities] =
    useState<DocumentsOptions>([])
  const [isRecordsInsertAfter, setIsRecordsInsertAfter] = useState(false)
  // marketing
  const [isEmail, setIsEmail] = useState(false)
  const [isViewEmail, setIsViewEmail] = useState(false)

  useEffect(() => {
    const getDocumentsValuesForSelection = () => {
      apiAuth.get<Array<Documents>>('clinics/documents/').then(({ data }) => {
        const documentsFormat = data?.map((doc) => {
          if (clinic_language === 'en') {
            return {
              label: doc?.name_en,
              value: doc?.id
            }
          }

          if (clinic_language === 'es') {
            return {
              label: doc?.name_en,
              value: doc?.id
            }
          }

          return {
            label: doc?.name_ptbr,
            value: doc?.id
          }
        })

        setOptionsDocuments(documentsFormat)
      })
    }

    const getDocumentsEntitiesValuesForSelection = () => {
      apiAuth
        .get<Array<Documents>>('clinics/entities/documents')
        .then(({ data }) => {
          const documentsFormat = data?.map((doc) => {
            if (clinic_language === 'en') {
              return {
                label: doc?.name_en,
                value: doc?.id
              }
            }

            if (clinic_language === 'es') {
              return {
                label: doc?.name_en,
                value: doc?.id
              }
            }

            return {
              label: doc?.name_ptbr,
              value: doc?.id
            }
          })

          setOptionsDocuments(documentsFormat)
        })
    }

    // getDocumentsEntitiesValuesForSelection()
    getDocumentsValuesForSelection()

    return () => {}
  }, [clinic_language])

  useEffect(() => {
    apiAuth.get<ClinicData>(`clinics/clinics/${clinicId}/`).then(({ data }) => {
      setClinic(data)
      if (data?.hours_attendance_start !== null) {
        setAttendanceStart(String(data?.hours_attendance_start))
      }

      if (data?.hours_attendance_cancel !== null) {
        setAttendanceFinish(String(data?.hours_attendance_cancel))
      }

      if (data?.hours_attendance_finish !== null) {
        setAttendanceFinishBySystem(String(data?.hours_attendance_finish))
      }

      if (data?.patient_birth_date) {
        setIsBirthDate(data?.patient_birth_date)
      }

      if (data?.patient_document) {
        setIsDocument(data?.patient_document)
      }

      if (data?.patient_origin) {
        setIsOrigin(data?.patient_origin)
      }

      if (data?.adding_itens_to_attendance) {
        setIsRecordsInsertAfter(data?.adding_itens_to_attendance)
      }

      if (data?.documents) {
        setDocumentsClinic(data?.documents)

        const documentsFormat = data?.documents?.map((doc) => {
          if (clinic_language === 'en') {
            return {
              label: doc?.name_en,
              value: doc?.id
            }
          }

          if (clinic_language === 'es') {
            return {
              label: doc?.name_en,
              value: doc?.id
            }
          }

          return {
            label: doc?.name_ptbr,
            value: doc?.id
          }
        })

        if (data?.documents_preferences?.length > 0) {
          const documentsPreferencesFormat = data?.documents_preferences?.map(
            (doc) => {
              return {
                value: doc?.id,
                label: doc?.label,
                isRequired: doc?.is_required,
                isView: doc?.is_view
              }
            }
          )

          setDocumentsSwitch(documentsPreferencesFormat)
        } else {
          const documentsFormatSwitch = data?.documents?.map((doc) => {
            if (clinic_language === 'en') {
              return {
                label: doc?.name_en,
                value: doc?.id,
                isRequired: doc?.is_required,
                isView: doc?.is_view
              }
            }

            if (clinic_language === 'es') {
              return {
                label: doc?.name_en,
                value: doc?.id,
                isRequired: doc?.is_required,
                isView: doc?.is_view
              }
            }

            return {
              label: doc?.name_ptbr,
              value: doc?.id,
              isRequired: doc?.is_required,
              isView: doc?.is_view
            }
          })
          setDocumentsSwitch(documentsFormatSwitch)
        }

        setDocuments(documentsFormat)
      }

      if (data?.patient_email) {
        setIsEmail(data?.patient_email)
      }

      if (data?.patient_view_email) {
        setIsViewEmail(data?.patient_view_email)
      }
    })

    return () => {}
  }, [clinicId, clinic_language])

  const handleSavePreferences: SubmitHandler<FormData> = async (values) => {
    const documentsBody = documentsSwitch?.map((doc) => {
      return {
        id: doc?.value
      }
    })

    const documentsPreferences = documentsSwitch?.map((doc) => {
      const getDocumentReference = documentsClinic?.find(
        (docClinic) => doc?.value === docClinic?.id
      )

      return {
        document: { ...getDocumentReference },
        is_view: doc?.isView,
        is_required: doc?.isRequired,
        label: doc?.label,
        id: doc?.value
      }
    })

    const payload = {
      patient_birth_date: isBirthDate,
      patient_document: isDocument,
      patient_origin: isOrigin,
      patient_email: isEmail,
      patient_view_email: isViewEmail,
      hours_attendance_start: Number(attendanceStart),
      hours_attendance_cancel: Number(attendanceFinish),
      hours_attendance_finish: Number(attendanceFinishBySystem),
      adding_itens_to_attendance: isRecordsInsertAfter,
      documents: documentsBody,
      documents_preferences: documentsPreferences
    }

    try {
      await apiAuth.put(`clinics/clinics/${clinicId}/`, payload)

      toast({
        status: 'success',
        title: 'Ação realizada com sucesso',
        description: 'Preferências atualizadas',
        variant: 'solid',
        duration: 3000
      })
    } catch (error: any) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Não foi possível atualizar as preferências',
        variant: 'solid',
        duration: 3000
      })
    }
  }

  return (
    <>
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('title')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center" />
          </Flex>
        </Header>
        <Sidebar />

        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleSavePreferences)}
        >
          <Stack spacing="4" w="100%">
            <CardContainer title={t('containerOne.title')}>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="60%">{t('table.t1')}</Th>
                    <Th>{t('table.t2')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Text>{t('containerOne.selected1')}</Text>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={isBirthDate}
                        name="birthDate"
                        onChange={(e) => setIsBirthDate(e.target.checked)}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerOne.selected3')}</Text>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={isOrigin}
                        name="isOrigin"
                        onChange={(e) => setIsOrigin(e.target.checked)}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerOne.selected4')}</Text>
                    </Td>
                    <Td>
                      <SelectComponent
                        options={optionsDocuments}
                        name="documents"
                        onChange={(e: DocumentsOptions) => {
                          if (e.length > documentsSwitch.length) {
                            // function difference array
                            const diference = diffArrays(
                              e,
                              documentsSwitch,
                              'value'
                            )

                            const addNewProps = diference?.map((difItem) => {
                              const newProps = {
                                isRequired: true,
                                isView: true
                              }
                              return { ...difItem, ...newProps }
                            })

                            const newValueSwitches: any = [
                              ...documentsSwitch,
                              ...addNewProps
                            ]

                            setDocumentsSwitch(newValueSwitches)
                          } else if (e.length < documentsSwitch.length) {
                            const diference: any = diffArraysSub(
                              documentsSwitch,
                              e,
                              'value'
                            )

                            setDocumentsSwitch(diference)
                          }
                          setDocuments(e)
                        }}
                        value={documents}
                        isMulti
                        placeholder={t('placeholder.document')}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerOne.selected5')}</Text>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={isEmail}
                        name="isEmail"
                        onChange={(e) => setIsEmail(e.target.checked)}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <Flex p="4" flexDir="column">
                <Text fontWeight="bold" mb="4">
                  Lista de Documentos selecionados
                </Text>
                {documentsSwitch?.map((item, index) => (
                  <HStack w="700px" mt="4" id={String(item?.value)}>
                    <Text mr="8" w="500px">
                      {item?.label}
                    </Text>
                    <Switch
                      isChecked={item?.isRequired}
                      name="document_required"
                      label={t('isRequired')}
                      onChange={(e) => {
                        const allValuesDocuments = [...documentsSwitch]
                        allValuesDocuments[index].isRequired = e.target.checked

                        setDocumentsSwitch(allValuesDocuments)
                      }}
                    />
                    <Switch
                      isChecked={item?.isView}
                      name="document_view"
                      label={t('isView')}
                      onChange={(e) => {
                        const allValuesDocuments = [...documentsSwitch]
                        allValuesDocuments[index].isView = e.target.checked

                        setDocumentsSwitch(allValuesDocuments)
                      }}
                    />
                  </HStack>
                ))}
              </Flex>
            </CardContainer>
            <CardContainer title={t('containerTwo.title')}>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="60%">{t('tableTwo.t1')}</Th>
                    <Th>{t('tableTwo.t2')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Text>{t('containerTwo.selected1')}</Text>
                    </Td>
                    <Td>
                      <Select
                        name="select"
                        value={attendanceStart}
                        onChange={(e) => setAttendanceStart(e.target.value)}
                        options={optionsDefaultHours}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerTwo.selected2')}</Text>
                    </Td>
                    <Td>
                      <Select
                        name="select"
                        options={optionsDefaultHours}
                        value={attendanceFinish}
                        onChange={(e) => setAttendanceFinish(e.target.value)}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerTwo.selected3')}</Text>
                    </Td>
                    <Td>
                      <Select
                        name="select"
                        options={optionsDefaultHours}
                        value={attendanceFinishBySystem}
                        onChange={(e) =>
                          setAttendanceFinishBySystem(e.target.value)
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerTwo.selected4')}</Text>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={isRecordsInsertAfter}
                        name="isRecordsInsertAfter"
                        onChange={(e) =>
                          setIsRecordsInsertAfter(e.target.checked)
                        }
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>{t('containerTwo.selected5')}</Text>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={isViewEmail}
                        name="isViewEmail"
                        onChange={(e) => setIsViewEmail(e.target.checked)}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </CardContainer>
            {/* <CardContainer title={t('containerTree.title')}>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="60%">{t('tableTree.t1')}</Th>
                    <Th>{t('tableTree.t2')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Text>{t('containerTree.selected4')}</Text>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={isCuponsActive}
                        name="isCuponsActive"
                        onChange={(e) => setIsCuponsActive(e.target.checked)}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </CardContainer>
            <CardContainer title={t('containerFour.title')}>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="60%">{t('tableFour.t1')}</Th>
                    <Th>{t('tableFour.t2')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Text>{t('containerFour.selected4')}</Text>
                    </Td>
                    <Td>
                      <SelectComponent
                        options={optionsDocumentsEntities}
                        name="documents"
                        onChange={(e: DocumentsOptions) => {
                          if (e.length > documentsSwitch.length) {
                            // function difference array
                            const diference = diffArrays(
                              e,
                              documentsSwitch,
                              'value'
                            )

                            const addNewProps = diference?.map((difItem) => {
                              const newProps = {
                                isRequired: true
                              }
                              return { ...difItem, ...newProps }
                            })

                            const newValueSwitches: any = [
                              ...documentsSwitch,
                              ...addNewProps
                            ]

                            setDocumentsSwitchEntities(newValueSwitches)
                          } else if (e.length < documentsSwitch.length) {
                            const diference: any = diffArraysSub(
                              documentsSwitch,
                              e,
                              'value'
                            )

                            setDocumentsSwitchEntities(diference)
                          }
                          setDocumentsEntities(e)
                        }}
                        value={documentsEntities}
                        isMulti
                        placeholder={t('placeholder.document')}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <Flex p="4" flexDir="column">
                <Text fontWeight="bold" mb="4">
                  Lista de Documentos selecionados
                </Text>
                {documentsSwitchEntities?.map((item, index) => (
                  <HStack w="700px" mt="4" id={String(item?.value)}>
                    <Text mr="8" w="500px">
                      {item?.label}
                    </Text>
                    <Switch
                      isChecked={item?.isRequired}
                      name="document_required"
                      label={t('isRequired')}
                      onChange={(e) => {
                        const allValuesDocuments = [...documentsSwitch]
                        allValuesDocuments[index].isRequired = e.target.checked

                        setDocumentsSwitch(allValuesDocuments)
                      }}
                    />
                  </HStack>
                ))}
              </Flex>
            </CardContainer> */}
            <Flex w="100%">
              <Box ml="auto">
                <Button
                  colorScheme="blue"
                  size="sm"
                  type="submit"
                  isLoading={formState.isSubmitting}
                >
                  {t('button')}
                </Button>
              </Box>
            </Flex>
            <Flex w="100%" h="300px" />
          </Stack>
        </Flex>
      </Box>
    </>
  )
}
