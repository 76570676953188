/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Stack,
  Text,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import { Input } from '../../components/Form/Input'
import { CardContainer } from '../../components/molecules/CardContainer'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Header } from '../../components/Header'
import { Sidebar } from '../../components/Sidebar'

import { Switch } from '../../components/Form/Switch'
import { PermissionComponent } from '../../components/Permissions'
import { useAttendance } from './service'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'

type FormData = {
  isActive: boolean
  observation: string
}

type User = {
  value: number
  label: string
}

type Users = Array<User>

type Transference = {
  id: string | number
  name: string
  isActive: boolean
  users: Users
  observation: string
  transferencePercentOne: string
  transferencePercentTwo: string
  transferenceNameOne: string
  transferenceNameTwo: string
}

type Transfers = {
  id: number
  rule_name: string
  observation: string | null
  users: [
    {
      id: number
      name: string
    }
  ]
  is_active: boolean
  percent_transfer: number
  percent_transfer2: number
  name_transfer: string
  name_transfer2: string
}

export function UpdateTagsServices() {
  const [t] = useTranslation('pageTagsServices')
  const attendanceService = useAttendance()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const [name, setName] = useState('')

  const router = useHistory()
  const { state }: any = router.location

  // transference
  const [transference, setTransference] = useState<Transference[]>([])
  const [indexExclude, setIndexExclude] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }

    if (state?.transfers) {
      if (state?.transfers?.length > 0) {
        const recoverTrans = state?.transfers?.map((trans: Transfers) => {
          const users = trans?.users?.map((user) => {
            return {
              value: user?.id,
              label: user?.name
            }
          })

          return {
            id: trans?.id,
            name: trans?.rule_name,
            isActive: trans?.is_active,
            users,
            observation: trans?.observation,
            transferencePercentOne: formatBrOrDollarDefault(
              String(trans?.percent_transfer * 100) || ''
            ),
            transferencePercentTwo: formatBrOrDollarDefault(
              String(trans?.percent_transfer2 * 100) || ''
            ),
            transferenceNameOne: trans?.name_transfer,
            transferenceNameTwo: trans?.name_transfer2
          }
        })

        setTransference(recoverTrans)
      }
    }
  }, [])

  const handleAddNewTransference = () => {
    const oldTransfer = [...transference]

    setTransference([
      ...oldTransfer,
      {
        id: uuidV4(),
        isActive: true,
        name: '',
        users: [],
        observation: '',
        transferencePercentOne: '',
        transferenceNameOne: '',
        transferenceNameTwo: '',
        transferencePercentTwo: ''
      }
    ])
  }

  const handleRemoveNewTransference = (index: number) => {
    const allTransferer = [...transference]
    allTransferer.splice(index, 1)
    setTransference(allTransferer)
  }

  const handleChangeObservation = (
    id: string | number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const data = {
      observation: event?.currentTarget?.value
    }

    const newValueTransferer = allTransferer.map((trans) => {
      return id === trans.id ? { ...trans, ...data } : trans
    })

    setTransference(newValueTransferer)
  }

  const handleChangeName = (
    id: string | number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const data = {
      name: event?.currentTarget?.value
    }

    const newValueTransferer = allTransferer.map((trans) => {
      return id === trans.id ? { ...trans, ...data } : trans
    })

    setTransference(newValueTransferer)
  }

  const handleChangeIsActive = (
    id: string | number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const data = {
      isActive: event?.target?.checked
    }

    const newValueTransferer = allTransferer.map((trans) => {
      return id === trans.id ? { ...trans, ...data } : trans
    })

    setTransference(newValueTransferer)
  }

  const handleChangePercentageOne = (
    id: string | number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const valueNumber =
      Number(remaskCaractersAll(event?.currentTarget?.value)) / 100

    if (valueNumber <= 100) {
      const data = {
        transferencePercentOne: event?.currentTarget?.value
      }

      const newValueTransferer = allTransferer.map((trans) => {
        return id === trans.id ? { ...trans, ...data } : trans
      })

      setTransference(newValueTransferer)
    }
  }

  const handleChangePercentageTwo = (
    id: string | number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const valueNumber =
      Number(remaskCaractersAll(event?.currentTarget?.value)) / 100

    if (valueNumber <= 100) {
      const data = {
        transferencePercentTwo: event?.currentTarget?.value
      }

      const newValueTransferer = allTransferer.map((trans) => {
        return id === trans.id ? { ...trans, ...data } : trans
      })

      setTransference(newValueTransferer)
    }
  }

  const handleChangeNameTwo = (
    id: string | number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const data = {
      transferenceNameTwo: event?.currentTarget?.value
    }

    const newValueTransferer = allTransferer.map((trans) => {
      return id === trans.id ? { ...trans, ...data } : trans
    })

    setTransference(newValueTransferer)
  }

  const handleChangeNameOne = (
    id: string | number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const allTransferer = [...transference]

    const data = {
      transferenceNameOne: event?.currentTarget?.value
    }

    const newValueTransferer = allTransferer.map((trans) => {
      return id === trans.id ? { ...trans, ...data } : trans
    })

    setTransference(newValueTransferer)
  }

  const findAddedItem = (arr1: Array<User>, arr2: Array<User>) => {
    const addedItem = arr2.filter((item) => !arr1.includes(item))
    return addedItem[0]
  }

  const handleChangeUser = (id: string | number, event: Users) => {
    let item: User | null = null
    const allTransferer = [...transference]

    const data = {
      users: event
    }

    allTransferer.forEach((trans) => {
      if (id === trans?.id) {
        const user = findAddedItem(trans?.users, event)
        item = user
      }
    })

    const addValueArrayPosition = allTransferer.map((trans) => {
      return id === trans.id ? { ...trans, ...data } : trans
    })

    const removeValueArrayPosition = addValueArrayPosition.map((trans) => {
      const oldArray = trans?.users
      const newValueArray = oldArray.filter(
        (array) => array.value !== item?.value
      )
      const newData = {
        users: newValueArray
      }

      return id !== trans.id ? { ...trans, ...newData } : trans
    })

    setTransference(removeValueArrayPosition)
  }

  const formatTransference = () => {
    const allTransfers = [...transference]

    const formatTransf: any = allTransfers.map((trans) => {
      const arrayUsers = trans?.users?.map((user) => {
        return {
          id: user?.value
        }
      })

      return {
        rule_name: trans?.name || format(new Date(), 'dd/MM/yyyy'),
        is_active: trans?.isActive,
        observation: trans?.observation || null,
        users: arrayUsers,
        percent_transfer:
          Number(remaskCaractersAll(trans?.transferencePercentOne)) / 100 || 0,
        percent_transfer2:
          Number(remaskCaractersAll(trans?.transferencePercentTwo)) / 100 || 0,
        name_transfer: trans?.transferenceNameOne || '',
        name_transfer2: trans?.transferenceNameTwo || ''
      }
    })

    return formatTransf
  }

  const handleUpdateTagService: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { isActive, observation } = values

    const transfers = formatTransference()

    attendanceService.updateAttendance({
      name: name || state.name,
      id: state.id,
      is_active: isActive,
      observation,
      transfers
    })
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('updateTitle')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/services/tags">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleUpdateTagService)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="65%">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  value={name}
                  isRequired
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setName(event.target.value.toUpperCase())
                  }
                  maxLength={10}
                />
              </Box>
              <Flex w="35%">
                <PermissionComponent
                  spiCode="SERVICE_GROUPS"
                  ipCode="INACTIVATE"
                >
                  <Box ml="auto" mr="6">
                    <Switch
                      defaultChecked={state.is_active}
                      label={t('create.container1.s1')}
                      {...register('isActive')}
                    />
                  </Box>
                </PermissionComponent>
              </Flex>
            </Stack>
            <Text fontSize="sm" my="2">
              {t('label.observation')}
            </Text>
            <Textarea
              {...register('observation')}
              name="observation"
              defaultValue={state?.observation}
              placeholder={t('create.container1.placeholder')}
            />
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="column" my="2" spacing="6">
              {transference?.map((trans, index) => (
                <Stack
                  w="100%"
                  key={trans.id}
                  direction="column"
                  borderRadius="16"
                  position="relative"
                  p="4"
                  border="1px solid gray"
                >
                  <CloseButton
                    position="absolute"
                    top="2"
                    right="4"
                    zIndex={99}
                    _hover={{ bg: 'red.500', color: 'white' }}
                    onClick={() => {
                      setIsOpen(true)
                      setIndexExclude(index)
                    }}
                  />
                  <Stack w="100%" direction="row">
                    <Box w="400px">
                      <Input
                        name="nameOfRule"
                        textTransform="uppercase"
                        value={trans?.name}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChangeName(trans?.id, event)
                        }}
                        label={t('create.container2.i1')}
                      />
                    </Box>
                    <Box w="120px">
                      <Switch
                        name="isActive"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleChangeIsActive(trans?.id, event)
                        }}
                        isChecked={trans?.isActive}
                        label={t('create.container2.i2')}
                      />
                    </Box>
                    <TextFieldControlled
                      name="observation"
                      placeholder=""
                      textAlign="left"
                      value={trans?.observation}
                      inputOnChange={(event) => {
                        handleChangeObservation(trans?.id, event)
                      }}
                      label={t('create.container2.i10')}
                    />
                  </Stack>
                  <Stack w="100%" direction="row">
                    <Box flex="1">
                      <SelectComponent
                        name="users"
                        options={attendanceService.users?.map((user) => {
                          return {
                            value: user?.id,
                            label: user?.name
                          }
                        })}
                        value={trans?.users}
                        onChange={(event: Users) => {
                          handleChangeUser(trans?.id, event)
                        }}
                        isMulti
                        placeholder="Selecionar usuários"
                        title={t('create.container2.i3')}
                      />
                    </Box>
                  </Stack>
                  <Stack w="100%" direction="row">
                    <Box w="320px">
                      <TextFieldControlled
                        name="isPercentResourceOne"
                        mask="porcentagem"
                        inputOnChange={(event) => {
                          handleChangePercentageOne(trans?.id, event)
                        }}
                        initialValue={trans?.transferencePercentOne}
                        label={t('create.container2.i8')}
                      />
                    </Box>
                    <Box w="320px">
                      <TextFieldControlled
                        name="isNamePercentOne"
                        inputOnChange={(event) => {
                          handleChangeNameOne(trans?.id, event)
                        }}
                        textAlign="left"
                        placeholder=""
                        textTransform="uppercase"
                        initialValue={trans?.transferenceNameOne}
                        label={t('create.container2.i9')}
                      />
                    </Box>
                  </Stack>
                  <Stack w="100%" direction="row">
                    <Box w="320px">
                      <TextFieldControlled
                        name="isPercentResourceTwo"
                        mask="porcentagem"
                        inputOnChange={(event) => {
                          handleChangePercentageTwo(trans?.id, event)
                        }}
                        initialValue={trans?.transferencePercentTwo}
                        label={t('create.container2.i11')}
                      />
                    </Box>
                    <Box w="320px">
                      <TextFieldControlled
                        name="isNamePercentTwo"
                        inputOnChange={(event) => {
                          handleChangeNameTwo(trans?.id, event)
                        }}
                        textAlign="left"
                        placeholder=""
                        textTransform="uppercase"
                        initialValue={trans?.transferenceNameTwo}
                        label={t('create.container2.i12')}
                      />
                    </Box>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Flex w="100%" justifyContent="flex-start">
              <Button
                bg="blue.300"
                onClick={() => handleAddNewTransference()}
                size="sm"
                color="white"
              >
                {t('create.container2.buttonAdd')}
              </Button>
            </Flex>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
          <Flex w="100%" h="550px" />
        </Stack>
      </Flex>
      <ModalConfirmExclude
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirmExclude={() => {
          handleRemoveNewTransference(indexExclude)
          setIsOpen(false)
        }}
        title="Confirma exclusão de repasse"
      >
        <Text>Gostaria realmente de excluir esta regra?</Text>
      </ModalConfirmExclude>
    </Box>
  )
}
