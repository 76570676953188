/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import { useHistory } from 'react-router'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import jwt_decode from 'jwt-decode'

import { CardContainer } from '../../../components/molecules/CardContainer'
import { Input } from '../../../components/Form/Input'

import { ArrowBack } from '../../../components/atoms/arrowBack'
import { Sidebar } from '../../../components/Sidebar'
import { Header } from '../../../components/Header'
import { InputPhone2 } from '../../../components/Form/InputPhone2'
import { SelectComponent } from '../../../components/Form/SelectComponent'

import { Switch } from '../../../components/Form/Switch'
import { useService } from './service'
import { useAddress } from '../../../utils/services/useAddress'
import { mask } from '../../../utils/mask'
import { StateOptions } from '../../../utils/json/states'
import { Select } from '../../../components/Form/Select'
import { TextFieldControlled } from '../../../components/Form/TextFieldControlled'
import { useAuth } from '../../../hooks/auth'
import { PermissionComponent } from '../../../components/Permissions'

type FormData = {
  registration: string
  trading_name: string
  company_name: string
  observation: string
  status: any
  email: string
  numero: string
  complemento: string
  cep: string
  rua: string
  bairro: string
  cidade: string
  estado: string
  phone: string
}

type ContactsData = {
  contact: string
  ddi_country: string
  id: string
  is_main: boolean
  type_of_contact: string
}

export function CreateSuppliers() {
  const [t] = useTranslation('pageSuppliers')
  const { decodeToken } = useAuth()
  const service = useService()
  const history = useHistory()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const [localMethod, setLocal] = useState('')
  const { state }: any = history.location
  const { errors } = formState
  const address = useAddress()
  const defaultOptionsDocument = [
    { value: '1', label: `${t('options.document.1')}` },
    { value: '2', label: `${t('options.document.2')}` },
    { value: '0', label: `${t('options.document.3')}` }
  ]

  // states
  const [countryDefault, setCountryDefault] = useState({
    value: '',
    label: ''
  })
  const [numbers, setNumbers] = useState<ContactsData[]>([
    {
      contact: '',
      ddi_country:
        decodeToken?.clinic?.clinic_contacts[0]?.ddi_country || '+55',
      id: '',
      is_main: true,
      type_of_contact: 'telefone'
    }
  ])
  const [valueDocumentSelect, setValueDocumentSelect] = useState('')
  const [typeDocument, setTypeDocument] = useState<any>()
  const [numberDocument, setNumberDocument] = useState('')
  const [trading_name, setTradingName] = useState('')
  const [company_name, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  // NUMBERS FUNCTIONS
  function handleAddNumbers() {
    setNumbers([
      ...numbers,
      {
        contact: '',
        ddi_country:
          decodeToken?.clinic?.clinic_contacts[0]?.ddi_country || '+55',
        id: '',
        is_main: false,
        type_of_contact: ''
      }
    ])
  }

  function handleRemoveNumbers(index: number) {
    const values = [...numbers]
    values.splice(index, 1)
    setNumbers(values)
  }

  function handleChangeInputNumbers(index: number, e: any) {
    const values: any = [...numbers]

    if (values[index].ddi_country === '+55') {
      values[index].contact = mask(e.target.value, '(99) 99999-9999')
    } else {
      values[index].contact = e.target.value
    }

    setNumbers(values)
  }

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  useEffect(() => {
    if (state?.document_type !== undefined) {
      setValueDocumentSelect(String(state.document_type))
    } else {
      setValueDocumentSelect('')
    }

    if (state?.trading_name !== undefined) {
      setTradingName(state.trading_name)
    }

    if (state?.company_name !== undefined) {
      setCompanyName(state.company_name)
    }

    if (state?.emails !== undefined) {
      if (state?.emails.length > 0) {
        setEmail(state.emails[0])
      }
    } else {
      setEmail('')
    }

    if (
      state?.document_number !== undefined &&
      state?.document_number !== null
    ) {
      setNumberDocument(String(state?.document_number))
    } else {
      setNumberDocument('')
    }

    if (state?.contacts_supplier !== undefined) {
      if (state?.contacts_supplier.length > 0) {
        setNumbers(state.contacts_supplier)
      }
    } else {
      setNumbers([
        {
          contact: '',
          ddi_country:
            decodeToken?.clinic?.clinic_contacts[0]?.ddi_country || '+55',
          id: '',
          is_main: true,
          type_of_contact: 'telefone'
        }
      ])
    }
  }, [])

  // select default country in configs
  useEffect(() => {
    if (decodeToken === undefined) {
      return
    }

    if (decodeToken.clinic_country === 'Brasil') {
      address.setSeledtedBR(true)
    }

    const country = [decodeToken.clinic_country]
    const ArrayCountry = address.optionsContries

    const SelectedDefault = ArrayCountry.map((count: any) =>
      country.find((cou) => {
        if (cou === count.value) {
          address.setSeletedCountry(count)
        }
      })
    )

    console.log(SelectedDefault)
  }, [decodeToken])

  const handleCreateNewSupplierOrUpdate: SubmitHandler<FormData> = async (
    values
  ) => {
    const contacts_supplier = numbers.map((item: ContactsData) => {
      if (item.contact !== '') {
        return {
          ddi_country: item.ddi_country,
          contact: item.contact,
          is_main: item.is_main,
          type_of_contact: item.type_of_contact
        }
      }

      return {
        invalid: true
      }
    })

    const payload = {
      registration: '',
      trading_name,
      company_name,
      observation: values.observation === '' ? null : values.observation,
      document_type: Number(valueDocumentSelect),
      document_number: numberDocument,
      is_active: values.status,
      emails: (email === '' ? [] : [email]) || [],
      address: {
        zip_code: address.cep || state?.address?.zip_code || undefined,
        street: address.address || state?.address?.street || undefined,
        number: values.numero || state?.address?.number || undefined,
        district: address.bairro || state?.address?.district || undefined,
        city: address.municipio || state?.address?.city || undefined,
        state:
          address.ufDefault.label || address?.seletedState.label || undefined,
        country:
          address.seletedCountry.label || state?.address?.country || undefined,
        observation:
          values.complemento || state?.address?.observation || undefined
      },
      contacts_supplier:
        contacts_supplier[0].invalid === true
          ? []
          : contacts_supplier || state?.contact_supplier || []
    }

    if (localMethod === 'create') {
      service.createRequest(payload)
    } else {
      const { id } = state

      service.updateRequest(payload, id)
    }
  }

  useEffect(() => {
    const token: any = localStorage.getItem('@Clinik:token')
    const tokenFormat: any = jwt_decode(token)

    if (tokenFormat.clinic_country !== undefined && !countryDefault.value) {
      setCountryDefault({
        value: tokenFormat.clinic_country,
        label: tokenFormat.clinic_country
      })

      address.setSeletedCountry({
        value: tokenFormat.clinic_country,
        label: tokenFormat.clinic_country
      })

      if (tokenFormat.clinic_country === ('Brasil' || 'Brasil')) {
        address.setSeledtedBR(true)

        address.handleBRSelectedAndShowStates('', true)
      }
    }
  }, [])

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  useEffect(() => {
    if (localMethod === 'update') {
      // default value endereco
      address.setCep(state?.address?.zip_code || '')
      address.setAddress(state?.address?.street || '')
      address.setBairro(state?.address?.district || '')
      address.setMunicipio(state?.address?.city || '')
      address.setSeledtedBR(true)
      address.optionsContries.map((contry: any) => {
        if (state?.address?.country === contry.label) {
          address.setMunicipio(state?.address?.city || '')
          if (contry !== null) {
            address.setSeletedCountry(contry)
          } else {
            address.setSeletedCountry('')
          }
        }
      })
      StateOptions.map((ufAux: any) => {
        if (state?.address?.state === ufAux.label) {
          address.setSelectedState(ufAux || '')
          address.setUfDefault(ufAux || '')
        }
      })
    }
  }, [localMethod])

  // change Mask for input document value
  useEffect(() => {
    switch (valueDocumentSelect) {
      case '1':
        setTypeDocument('cpf')
        break

      case '2':
        setTypeDocument('cnpj')
        break

      case '3':
        setTypeDocument('def')
        break

      default:
        break
    }
  }, [valueDocumentSelect])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create' ? t('create.title') : t('updateTitle')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/financial/suppliers">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateNewSupplierOrUpdate)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack w="100%" direction="row" my="2" spacing="6">
              <Box w="20%">
                <Select
                  label={t('create.container1.i1')}
                  options={defaultOptionsDocument}
                  placeholder={t('create.container1.placeholder.doc')}
                  name="document_type"
                  value={valueDocumentSelect}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setValueDocumentSelect(event.target.value)
                  }
                />
              </Box>
              <Box w="20%">
                <TextFieldControlled
                  label={t('create.container1.i2')}
                  mask={typeDocument}
                  textAlign="left"
                  placeholder=""
                  name="document_number"
                  initialValue={numberDocument}
                  onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    setNumberDocument(event.currentTarget.value)
                  }}
                />
              </Box>
              <Box w="20%">
                <Input
                  label={t('create.container1.i3')}
                  onChange={(event) =>
                    setTradingName(event.target.value.toUpperCase())
                  }
                  value={trading_name}
                  maxLength={40}
                  isRequired
                  name="trading_name"
                  error={errors.trading_name}
                />
              </Box>
              <Box w="40%">
                <Input
                  label={t('create.container1.i4')}
                  onChange={(event) =>
                    setCompanyName(event.target.value.toUpperCase())
                  }
                  value={company_name}
                  maxLength={40}
                  name="company_name"
                  error={errors.company_name}
                />
              </Box>
            </Stack>
            <Stack direction="row" my="2" spacing="6">
              <Box w="100%">
                <Text mb="2" fontSize="sm">
                  {t('create.container1.i5')}
                </Text>
                <Textarea
                  defaultValue={state?.observation}
                  placeholder={t('create.container1.placeholder.observation')}
                  {...register('observation')}
                />
              </Box>
            </Stack>
            <Flex>
              {localMethod === 'create' ? (
                <Switch
                  defaultChecked={
                    state?.is_active !== undefined ? state?.is_active : true
                  }
                  label={t('create.container1.i6')}
                  {...register('status')}
                />
              ) : (
                <PermissionComponent spiCode="ENTITIES" ipCode="INACTIVATE">
                  <Switch
                    defaultChecked={
                      state?.is_active !== undefined ? state?.is_active : true
                    }
                    label={t('create.container1.i6')}
                    {...register('status')}
                  />
                </PermissionComponent>
              )}
            </Flex>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="row" my="2" spacing="2">
              <Box w="30%">
                <SelectComponent
                  title={t('create.container2.iPais')}
                  placeholder="Selecione um país"
                  options={address.optionsContries}
                  name="pais"
                  value={address.seletedCountry}
                  onChange={(e: any) =>
                    address.handleBRSelectedAndShowStates(e)
                  }
                />
              </Box>
              <Box>
                <Input
                  label={t('create.container2.iCEP')}
                  {...register('cep')}
                  isDisabled={address.loadingCompletAddress}
                  value={address.cep}
                  onChange={(e) => address.handleCompletAddress(e.target.value)}
                />
              </Box>
              <Box w="40%">
                <Input
                  label={t('create.container2.iRua')}
                  {...register('rua')}
                  value={address.address}
                  onChange={(e) => address.setAddress(e.target.value)}
                />
              </Box>
              <Box>
                <Input
                  label={t('create.container2.iNumero')}
                  defaultValue={state?.address?.number}
                  {...register('numero')}
                  error={errors.numero}
                />
              </Box>
            </Stack>
            <Stack direction="row" my="2" spacing="2">
              <Box w="30%">
                <Input
                  label={t('create.container2.iComplemento')}
                  {...register('complemento')}
                  defaultValue={state?.address?.observation}
                  error={errors.complemento}
                />
              </Box>
              <Box>
                <Input
                  label={t('create.container2.iBairro')}
                  {...register('bairro')}
                  value={address.bairro}
                  onChange={(e) => address.setBairro(e.target.value)}
                />
              </Box>
              <Box w="30%">
                <Input
                  label={t('create.container2.iCidade')}
                  {...register('cidade')}
                  value={address.municipio}
                  onChange={(e) => address.setMunicipio(e.target.value)}
                />
              </Box>
              {address.selectedBR ? (
                <Box w="30%">
                  <SelectComponent
                    title={t('create.container2.iEstado')}
                    placeholder="Selecione um estado"
                    options={address.ufs}
                    name="estado"
                    value={address.seletedState}
                    onChange={(e: any) => address.setSelectedState(e)}
                  />
                </Box>
              ) : (
                <Box w="30%">
                  <Input
                    label={t('create.container2.iEstado')}
                    {...register('estado')}
                    defaultValue={state?.address?.state}
                    error={errors.estado}
                  />
                </Box>
              )}
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container3.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="50%">
                <Input
                  label={t('create.container3.iEmail')}
                  defaultValue={
                    state?.emails !== undefined ? state.emails[0] : ''
                  }
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={errors.email}
                />
              </Box>
            </Stack>
            <Stack my="2" spacing="6">
              {numbers.map((number, index: number) => (
                <Stack direction="row" key={`number${index}`}>
                  <Stack w="50%" direction="row">
                    <InputPhone2
                      label={`${t('create.container3.tele')} ${index + 1}`}
                      type="text"
                      size="md"
                      error={errors.phone}
                      name="number"
                      defaultValues={{
                        ddi: numbers[0].ddi_country
                      }}
                      getValues={(value) => {
                        const values = [...numbers]
                        values[index].ddi_country = value?.ddi_country
                        setNumbers(values)
                      }}
                      value={numbers[0].contact}
                      onChange={(e) => handleChangeInputNumbers(index, e)}
                    />
                  </Stack>

                  <Flex
                    pt="4"
                    direction="column"
                    align="center"
                    justify="center"
                  >
                    {numbers.length > 1 && (
                      <Box>
                        <Button
                          type="button"
                          colorScheme="red"
                          size="xs"
                          onClick={() => handleRemoveNumbers(index)}
                          mb="2"
                        >
                          -
                        </Button>
                      </Box>
                    )}
                  </Flex>
                </Stack>
              ))}
            </Stack>
            <Box mt="3">
              {/* <Button
                size="md"
                fontSize="sm"
                bg="green.300"
                color="#fff"
                isLoading={formState.isSubmitting}
                onClick={() => handleAddNumbers()}
              >
                {t('create.container3.btnAdd')}
              </Button> */}
            </Box>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
